const colors = {
  primaryColor: '#1f2937',
  secondaryColor: '#e5e7eb',
  white: '#ffffff',
  greySoft: '#f3f4f6',
  grey: '#e5e7eb',
  grey616161: '#616161',
  greyMid: '#d1d5db',
  greyDark: '#9ca3af',
  greyDarker: '#6b7280',
  greyDarkest: '#4b5563',
  greyDarkestTransparent: 'rgba(75, 85, 99, 0.16)',
  black: '#1f2937',
  slate: '#677c98',
  slateDark: '#4b5b71',
  greenSofter: '#effdec',
  greenSoft: '#ecfdf5',
  greenLight: '#10b981',
  green: '#059669',
  purpleSoft: '#f5f3ff',
  purpleLight: '#a78bfa',
  purple: '#7c3aed',
  blueSoft: '#eff6ff',
  blueLight: '#3b82f6',
  blue: '#2563eb',
  red: '#e31b0c',
  redSofter: '#fff7f7',
  redSoft: '#fef2f2',
  pinkSoft: '#fdf2f8',
  yellowSoft: '#fffbeb',
  petrol: '#43A49E',
  petrolActive: '#3d9691',
}
export default colors
