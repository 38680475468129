import React, { Suspense } from 'react'
import { Route, Redirect } from 'react-router-dom'

const isLogin = () => true

interface Props {
  component?: any
  restricted?: boolean
  path: string
  exact: boolean
}

const PublicRoute: React.FC<Props> = ({
  component: Component = undefined,
  restricted = false,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isLogin() && restricted ? (
        <Redirect to="/dashboard" />
      ) : (
        <Suspense fallback={null}>
          <Component {...props} />
        </Suspense>
      )
    }
  />
)

PublicRoute.defaultProps = {
  restricted: false,
  component: undefined,
}

export default PublicRoute
