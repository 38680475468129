import React, { StrictMode } from 'react'
import { render } from 'react-dom'
import { ThemeProvider } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import reportWebVitals from './reportWebVitals'
import Router from './routes/Router'
import { DataContextProvider } from './utils/Context/DataContext'
import GlobalStyle from './utils/GlobalStyle'
import { Storage } from './utils/storage'
import theme from './utils/theme'
import './Localization'

render(
  <StrictMode>
    <DataContextProvider>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <Storage />
            <Router />
            <CssBaseline />
            <GlobalStyle />
          </ThemeProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </DataContextProvider>
  </StrictMode>,
  document.getElementById('root') as HTMLElement
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
