import { lazyWithRetry } from '../utils/lazyWithRetry'

export interface IRoute {
  path: string
  exact: boolean
  isPublic: boolean
  restricted?: boolean
  Component: React.LazyExoticComponent<React.ComponentType<any>>
}

const routes = [
  {
    path: '/',
    exact: true,
    isPublic: false,
    Component: lazyWithRetry(() => import('../views/Home')),
  },
]

export default routes
