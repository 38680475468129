export const DATA_KEY = 'data'
export const setStorageData = (data: object) => {
  localStorage.setItem(DATA_KEY, JSON.stringify(data))
}
export const getStorageData = () => {
  const data = localStorage.getItem(DATA_KEY)
  return data ? JSON.parse(data) : undefined
}
export const removeStorageData = () => {
  localStorage.removeItem(DATA_KEY)
}
