import React from 'react'
import { Global, css } from '@emotion/react'
import { useTheme } from '@mui/material/styles'

const GlobalStyle: React.FC = () => {
  const theme = useTheme()
  return (
    <Global
      styles={css`
        html {
          overflow: auto;
        }
        body {
          font-family: ${theme.fontFamily}, 'Fira Sans, sans-serif';
          margin: 0;
          font-weight: 300;
          color: ${theme.palette.black};

          overflow: auto;
          -ms-overflow-style: none;
          scrollbar-width: thin;
        }
      `}
    />
  )
}

export default GlobalStyle
