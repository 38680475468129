import { Dispatch, SetStateAction, useState, createContext } from 'react'
import { getStorageData, setStorageData } from '../helper'

const initialValues = {
  data: getStorageData() || {},
}

type ContextType = {
  data: {
    income?: any[]
    monthlyExpenses?: any[]
    quarterlyExpenses?: any[]
    annualExpenses?: any[]
  }
  setData?: Dispatch<SetStateAction<object>>
}

const context = {
  data: initialValues.data,
  setData: () => {},
} as ContextType

const DataContext = createContext(context)

interface DataContextProviderProps {
  children: React.ReactNode
}

export const DataContextProvider: React.FC<DataContextProviderProps> = ({ children }) => {
  const [data, setLocalData] = useState(initialValues.data)
  const setData = (jsonData: object) => {
    setLocalData(jsonData)
    setStorageData(jsonData)
  }

  return <DataContext.Provider value={{ data, setData }}>{children}</DataContext.Provider>
}

export default DataContext
