import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import routes, { IRoute } from './routes'

const Router = () => (
  <BrowserRouter>
    <Route
      render={(state: any) => {
        const { location } = state
        return (
          <Switch location={location}>
            {routes?.map(({ path, Component, exact, isPublic, restricted }: IRoute) =>
              isPublic ? (
                <PublicRoute
                  key={path}
                  restricted={restricted}
                  component={Component}
                  path={path}
                  exact={exact}
                />
              ) : (
                <PrivateRoute key={path} component={Component} path={path} exact={exact} />
              )
            )}
            <Route component={() => <Redirect to="/cases" />} />
          </Switch>
        )
      }}
    />
  </BrowserRouter>
)

export default Router
