import React, { Suspense } from 'react'
import { Route, Redirect } from 'react-router-dom'
import Layout from '../layouts'

const isLogin = () => true

interface IPrivateRoute {
  component: any
  path: string
  exact: boolean
}

const PrivateRoute: React.FC<IPrivateRoute> = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLogin() ? (
        <Layout>
          <Suspense fallback={null}>
            <Component {...props} />
          </Suspense>
        </Layout>
      ) : (
        <Redirect to="/login" />
      )
    }
  />
)

export default PrivateRoute
