import { Components } from '@mui/material/styles/components'
import colors from './colors'

export type { Components }
const { greySoft, greyMid, greyDarker, blueLight } = colors

const components = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          backgroundColor: greySoft,
        },
        '&.MuiOutlinedInput': {},
        '&.MuiOutlinedInput-root': {
          '& fieldset': { borderColor: greyMid },
          '&:hover fieldset': { borderColor: greyMid, borderWidth: '2px' },
          '&.Mui-focused fieldset': { borderColor: blueLight },
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      outlined: {
        color: greyDarker,
        '&$disabled': {
          color: greyDarker,
        },
      },
      marginDense: {
        transform: 'translate(10px, 10px) ',
        color: greyDarker,
        '&$disabled': {
          color: greyDarker,
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      elevation4: {
        boxShadow: 'none',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      wrapper: {
        fontSize: '1.125rem',
      },
    },
  },
} as Components

export default components
