import { Color } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import colors from './colors'
import components, { Components } from './theme-components'

declare module '@mui/material/styles' {
  interface Theme {
    palette: Palette
    petrol: string
    black: string
    breakpoints: {
      values: {
        xs: number
        sm: number
        md: number
        lg: number
        xl: number
      }
      down: (key: keyof Theme['breakpoints']['values']) => string
      up: (key: keyof Theme['breakpoints']['values']) => string
    }
    fontFamily: string
  }

  interface TypeBackground {
    default: string
    paper: string
    level1: string
    level2: string
  }
  interface TypeText {
    primary: string
    secondary: string
    disabled: string
    hint: string
  }
  export interface Palette {
    petrol: string
    black: string
    greyDarker: string
    greenSoft: string
    yellowSoft: string
    blueSoft: string
    redSoft: string
    background: TypeBackground
    text: TypeText
    grey: Color
    purpleSoft: string

    greenSofter: string
    pinkSoft: string

    petrolActive: string
  }
  interface PaletteOptions {
    petrol: string
    purpleSoft: string
    type: string
    blueSoft: string
    greenSoft: string
    greenSofter: string
    pinkSoft: string
    redSoft: string
    yellowSoft: string
    petrolActive: string
  }
  // allow configuration using `createTheme`

  interface ThemeOptions {
    name?: string
    fontFamily?: string
    petrol?: string
    components?: Components<Omit<Theme, 'components'>> | undefined
  }
}

/* eslint-disable no-unused-vars */
// TODO: Remove all unused after setting all themevariables
const {
  primaryColor,
  secondaryColor,
  white,
  greySoft,
  grey,
  greyMid,
  greyDark,
  grey616161,
  greyDarker,
  greyDarkest,
  greyDarkestTransparent,
  black,
  slate,
  slateDark,
  greenSofter,
  greenSoft,
  green,
  purpleSoft,
  blueSoft,
  redSoft,
  pinkSoft,
  yellowSoft,
  petrol,
  petrolActive,
} = colors
const palette = {
  ...colors,
  common: { black, white },
  type: 'light',
  primary: {
    main: primaryColor,
    light: 'rgb(77, 171, 245)',
    dark: 'rgb(23, 105, 170)',
    contrastText: '#fff',
  },
  secondary: {
    main: secondaryColor,
    light: 'rgb(247, 51, 120)',
    dark: 'rgb(171, 0, 60)',
    contrastText: black,
  },
  error: { light: '#e57373', main: '#f44336', dark: '#d32f2f', contrastText: '#fff' },
  warning: {
    light: '#ffb74d',
    main: '#ff9800',
    dark: '#f57c00',
    contrastText: primaryColor,
  },
  info: { light: '#64b5f6', main: primaryColor, dark: '#1976d2', contrastText: '#fff' },
  success: {
    light: '#81c784',
    main: '#4caf50',
    dark: '#388e3c',
    contrastText: primaryColor,
  },
  grey: {
    50: greySoft,
    100: grey,
    200: greyMid,
    300: greyDark,
    400: greyDarker,
    500: greyDarkest,
    600: slate,
    700: slateDark,
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A500: greyDarkestTransparent,
    A700: grey616161,
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  text: {
    primary: primaryColor,
    secondary: '#4b5563',
    disabled: greyDarkest,
    hint: 'rgba(0, 0, 0, 0.38)',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: '#fff',
    default: '#fff',
    level2: '#f5f5f5',
    level1: '#fff',
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    hoverOpacity: 0.04,
    selected: 'rgba(0, 0, 0, 0.08)',
    selectedOpacity: 0.08,
    disabled: greyMid,
    disabledBackground: green,
    disabledOpacity: 0.38,
    focus: 'rgba(0, 0, 0, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
  },
  purpleSoft,
  blueSoft,
  greenSoft,
  greenSofter,
  pinkSoft,
  redSoft,
  yellowSoft,
  petrol,
  petrolActive,
}
const theme = createTheme({
  name: 'm2',
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components,
  fontFamily: '"Fira Sans", sans-serif',
  palette,
  shape: {
    borderRadius: 4,
  },
  typography: () => ({
    htmlFontSize: 16,
    fontFamily: '"Fira Sans", sans-serif',
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    lineHeightSmall: '0.875rem',
    lineHeightNormal: '1.25rem',
    lineHeightBig: '1.625rem',
    lineHeightBigger: '2.375rem',
    lineHeightBiggest: '2.5rem',
    h1: {
      fontFamily: '"Fira Sans", sans-serif',
      fontWeight: 'bold',
      fontSize: '2.5rem',
      lineHeight: 1.2,
      letterSpacing: 'normal',
    },
    h2: {
      fontFamily: '"Fira Sans", sans-serif',
      fontWeight: 400,
      fontSize: '2rem',
      lineHeight: 1.1875,
    },
    h3: {
      fontFamily: '"Fira Sans", sans-serif',
      fontWeight: 400,
      fontSize: '3rem',
      lineHeight: 1.167,
      letterSpacing: '0em',
    },
    h4: {
      fontFamily: '"Fira Sans", sans-serif',
      fontWeight: 'normal',
      fontSize: '1rem',
      lineHeight: 1.25,
      letterSpacing: 'normal',
      color: greyDarkest,
    },
    h5: {
      fontFamily: '"Fira Sans", sans-serif',
      fontWeight: 400,
      fontSize: '1.5rem',
      lineHeight: 1.334,
      letterSpacing: '0em',
    },
    h6: {
      fontFamily: '"Fira Sans", sans-serif',
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
    },
    tabBarCurrent: {
      fontFamily: '"Fira Sans", sans-serif',
      fontWeight: 400,
      fontSize: '1.25rem',
      lineHeight: 1,
      color: primaryColor,
    },
    subtitle1: {
      fontFamily: '"Fira Sans", sans-serif',
      fontSize: '1.25rem',
      fontWeight: 400,
      lineHeight: 1.2,
      color: black,
    },
    subtitle2: {
      fontFamily: '"Fira Sans", sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.57,
      letterSpacing: '0.00714em',
    },
    body1: {
      fontFamily: '"Fira Sans", sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    body2: {
      fontFamily: '"Fira Sans", sans-serif',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    button: {
      fontFamily: '"Fira Sans", sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },
    caption: {
      fontFamily: '"Fira Sans", sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
    },
    overline: {
      fontFamily: '"Fira Sans", sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 2.66,
      letterSpacing: '0.08333em',
      textTransform: 'uppercase',
    },
  }),
})

export default theme
