import { useEffect, useState, useCallback } from 'react'
import { useData } from './hooks'

const secret = 'secret'
// eslint-disable-next-line import/prefer-default-export
export const useStorage = () => {
  const { data, setData } = useData()

  const [focused, setFocused] = useState(false)

  const handleFocus = () => setFocused(true)
  const handleBlur = () => setFocused(false)

  const handleStorageChange = useCallback(
    e => {
      const { key, newValue } = e
      if (key === secret) {
        setData?.(JSON.parse(newValue))
      }
    },
    [setData]
  )

  useEffect(() => {
    setFocused(document.hasFocus())
  }, [])

  useEffect(() => {
    if (!focused) window.addEventListener('storage', handleStorageChange)

    window.addEventListener('focus', handleFocus)
    window.addEventListener('blur', handleBlur)
    return () => {
      if (!focused) window.removeEventListener('storage', handleStorageChange)

      window.removeEventListener('focus', handleFocus)
      window.removeEventListener('blur', handleBlur)
    }
  }, [focused, handleStorageChange])

  useEffect(() => {
    if (!focused) return
    localStorage.setItem(secret, JSON.stringify(data))
  }, [data, focused])
}

export const Storage = () => {
  useStorage()
  return null
}

export default useStorage
